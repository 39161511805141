import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "source" ]
  static values = {
    successDuration: {
      type: Number,
      default: 2000
    },

    successContent: {
      type: String,
      default: "✓"
    }
  }

  connect(){
    if (!this.hasButtonTarget) return

    if (!navigator.clipboard) {
      this.buttonTarget.classList.add("hidden")
      return
    }
    this.originalButtonHTML = this.buttonTarget.innerHTML
  }

  copy(event) {
    event.preventDefault()

    const text = this.sourceTarget.innerHTML || this.sourceTarget.value
    navigator.clipboard.writeText(text).then(() => this.copied())
  }

  copied () {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerText = this.successContentValue

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalButtonHTML
    }, this.successDurationValue)
  }
}