import { Application } from "@hotwired/stimulus"

import DarkToggle from "../controllers/dark_toggle_controller"
import Shuffle from "../controllers/shuffle_controller"
import Clipboard from "../controllers/clipboard_controller"
import { Toggle, Modal } from "tailwindcss-stimulus-components"
window.Stimulus = Application.start()

Stimulus.register("dark-toggle", DarkToggle)
Stimulus.register("shuffle", Shuffle)
Stimulus.register("clipboard", Clipboard)
Stimulus.register("toggle", Toggle)
Stimulus.register("modal", Modal)