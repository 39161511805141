import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    mode: {
      type: String,
      default: 'light'
    }
  }

  connect() {
    if (localStorage.getItem('mode') === 'dark') {
      this.setDarkMode()
    } else {
      this.setLightMode()
    }
  }

  toggle(event) {
    event.preventDefault()

    if (this.isDark()) {
      this.setLightMode()
    } else {
      this.setDarkMode()
    }
  }

  isDark() {
    return document.querySelector("html").classList.contains('dark')
  }

  isLight() {
    return !document.querySelector("html").classList.contains('dark')
  }

  setDarkMode() {
    localStorage.setItem('mode', 'dark')
    document.querySelector("html").classList.add('dark')
    document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#18181b');
  }

  setLightMode() {
    localStorage.setItem('mode', 'light')
    document.querySelector("html").classList.remove('dark')
    document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#f3f4f6');
  }
}