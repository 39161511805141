import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.shuffle();
  }

  shuffle() {
    const list = this.element.children;
    for (let i = list.length; i >= 0; i--) {
      this.element.appendChild(list[Math.random() * i | 0]);
    }
  }
}
